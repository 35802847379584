import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {  

  SERVER_URL = "https://mobimartech.org/contact.php";
  uploadForm: FormGroup;  
  
  ngOnInit() {
    
  }
  myform: FormGroup;
  name:string;
  email:string;
  subject:string;
  msg:string;

  hname:string ='Name';
  hemail:string = 'Email';
  hsubject:string = 'Subject';
  hmsg:string ='Your Message';

  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient) {
    this.uploadForm = this.formBuilder.group({name: [''],email: [''],subject: [''],message: ['']})
  }

  onSubmit() {
    const params = new HttpParams()
    .set('name', (<HTMLFormElement>document.getElementById("name")).value)
    .set('email', (<HTMLFormElement>document.getElementById("email")).value)
    .set('subject', (<HTMLFormElement>document.getElementById("subject")).value)
    .set('message', (<HTMLFormElement>document.getElementById("message")).value)
    
    console.log(params.toString());

    this.httpClient.get<any>(this.SERVER_URL, {params}).subscribe(
      (res) => console.log(res),
      (err) => console.log(err)
    );

    Swal.fire({
      icon: 'success',
      title: 'Done!',
      text: 'Form submitted successfully!'
    }).then((result) => {
      if (result.value) {
        (<HTMLFormElement>document.getElementById("form")).reset();
      }
    })
  }

}
