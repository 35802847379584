import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Mobile-Content',
  templateUrl: './Mobile-Content.component.html',
  styleUrls: ['./Mobile-Content.component.css']
})
export class MobileContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
