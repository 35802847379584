import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coverage',
  templateUrl: './coverage.component.html',
  styleUrls: ['./coverage.component.css']
})
export class CoverageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  isShown1 = true;
  show1() {
    this.isShown1 = !this.isShown1;
  }

  isShown2 = true;
  show2() {
    this.isShown2 = !this.isShown2;
  }

  isShown3 = true;
  show3() {
    this.isShown3 = !this.isShown3;
  }
  
  isShown4 = true;
  show4() {
    this.isShown4 = !this.isShown4;
  }
  
  isShown5 = true;
  show5() {
    this.isShown5 = !this.isShown5;
  }
  
  isShown6 = true;
  show6() {
    this.isShown6 = !this.isShown6;
  }
  
  isShown7 = true;
  show7() {
    this.isShown7 = !this.isShown7;
  }
  
  isShown8 = true;
  show8() {
    this.isShown8 = !this.isShown8;
  }
  
  isShown9 = true;
  show9() {
    this.isShown9 = !this.isShown9;
  }
  
  isShown10 = true;
  show10() {
    this.isShown10 = !this.isShown10;
  }
  
  isShown11 = true;
  show11() {
    this.isShown11 = !this.isShown11;
  }
  
  isShown12 = true;
  show12() {
    this.isShown12 = !this.isShown12;
  }
  
  isShown13 = true;
  show13() {
    this.isShown13 = !this.isShown13;
  }
  
  isShown14 = true;
  show14() {
    this.isShown14 = !this.isShown14;
  }
  
  isShown15 = true;
  show15() {
    this.isShown15 = !this.isShown15;
  }
  
  isShown16 = true;
  show16() {
    this.isShown16 = !this.isShown16;
  }
  
  isShown17 = true;
  show17() {
    this.isShown17 = !this.isShown17;
  }
  
  isShown18 = true;
  show18() {
    this.isShown18 = !this.isShown18;
  }
  
  isShown19 = true;
  show19() {
    this.isShown19 = !this.isShown19;
  } 
 isShown20 = true;
  show20() {
    this.isShown20 = !this.isShown20;
  }

}
