import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms-portal',
  templateUrl: './sms-portal.component.html',
  styleUrls: ['./sms-portal.component.css']
})
export class SMSPortalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
   
  }
  

}
