import { Component, OnInit } from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-Digital-Marketing',
  templateUrl: './Digital-Marketing.component.html',
  styleUrls: ['./Digital-Marketing.component.css']
})
export class DigitalMarketingComponent implements OnInit {

  constructor() { }

  ngOnInit() { 
    
    $(function() {
    $('.accordion').find('.accordion-title').on('click', function(){
        // Adds Active Class
        $(this).toggleClass('active');
        // Expand or Collapse This Panel
        $(this).next().slideToggle('fast');
        // Hide The Other Panels
        $('.accordion-content').not($(this).next()).slideUp('fast');
        // Removes Active Class From Other Titles
        $('.accordion-title').not($(this)).removeClass('active');		
    });
  });
  }

}
