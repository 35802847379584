import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-games-portal',
  templateUrl: './games-portal.component.html',
  styleUrls: ['./games-portal.component.css']
})
export class GamesPortalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
