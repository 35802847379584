 import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-repair',
  templateUrl: './repair.component.html',
  styleUrls: ['./repair.component.css']
})
export class RepairComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // if(localStorage.getItem("firstTime")==null){
      // Swal.fire({
        // imageUrl: 'assets/img/barcelona.jfif',
        // imageWidth: 450,
        // imageHeight: 250,
        // confirmButtonText:"Learn More!",
        // showCancelButton: true,
        // cancelButtonText:"Exit"
      // }).then((result) => {
        // if (result.value) {
          // window.open('https://www.mwcbarcelona.com/', '_blank');
        // }
      // })
      // localStorage.setItem("firstTime","done");
   // }
  }

}
