import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { TeamComponent } from './team/team.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { ContactComponent } from './contact/contact.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DevelopersComponent } from './developers/developers.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { RepairComponent } from './repair/repair.component';
import { VasServicesComponent } from './vas-services/vas-services.component';
import { TeleSmartComponent } from './TeleSmart/TeleSmart.component';
import { DeckComponent } from './deck/deck.component';
import { MobileContentComponent } from './Mobile-Content/Mobile-Content.component';
import { DigitalMarketingComponent } from './Digital-Marketing/Digital-Marketing.component';
import { GamesPortalComponent } from './games-portal/games-portal.component';
import { SMSPortalComponent } from './sms-portal/sms-portal.component';
import { MMTNetworkComponent } from './mmt-network/mmt-network.component';
import { CoverageComponent } from './coverage/coverage.component';

const routes: Routes = [
  // { path: '', redirectTo: '/home', pathMatch: 'full' }, // for defaault redirecting
  { path: '', component: HomeComponent },
  { path: 'startup', component: HomeComponent },
  { path: 'developers', component: DevelopersComponent },
  { path: 'web-hosting', component: WebHostingComponent },
  { path: 'home', component: RepairComponent },
  { path: 'about', component: AboutComponent },
  { path: 'Vas-Services', component: VasServicesComponent },
  { path: 'team', component: TeamComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'deck', component: DeckComponent },
  { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'Mobile-Content', component: MobileContentComponent },
  { path: 'TeleSmart', component: TeleSmartComponent },
  { path: 'Digital-Marketing', component: DigitalMarketingComponent },
  { path: 'Games-Portal', component: GamesPortalComponent },
  { path: 'SMS-Portal', component:SMSPortalComponent},
  { path: 'MMT-Network', component:MMTNetworkComponent},
  { path: 'Coverage', component:CoverageComponent},
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
