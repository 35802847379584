import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vas-services',
  templateUrl: './vas-services.component.html',
  styleUrls: ['./vas-services.component.css']
})
export class VasServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
