import { Component, OnInit } from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-deck',
  templateUrl: './deck.component.html',
  styleUrls: ['./deck.component.css']
})
export class DeckComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(function() {
      $('.accordion').find('.accordion-title').on('click', function(){
          // Adds Active Class
          $(this).toggleClass('active');
          // Expand or Collapse This Panel
          $(this).next().slideToggle('fast');
          // Hide The Other Panels
          $('.accordion-content').not($(this).next()).slideUp('fast');
          // Removes Active Class From Other Titles
          $('.accordion-title').not($(this)).removeClass('active');		
      });
    });
  }

  isShown1 = true;
  show1() {
    this.isShown1 = !this.isShown1;
  }

  isShown2 = true;
  show2() {
    this.isShown2 = !this.isShown2;
  }

  isShown3 = true;
  show3() {
    this.isShown3 = !this.isShown3;
  }

}
