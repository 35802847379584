import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2OdometerModule } from 'ng2-odometer';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { CarouselModule } from 'ngx-owl-carousel-o';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgwWowModule } from 'ngx-wow';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { IconsModule } from './icons/icons.module';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';
import { FunfactComponent } from './funfact/funfact.component';
import { WorksComponent } from './home/works/works.component';
import { FeedbackComponent } from './home/feedback/feedback.component';
import { PartnerComponent } from './partner/partner.component';
import { AboutComponent } from './about/about.component';
import { HomeTeamComponent } from './home/home-team/home-team.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { ContactComponent } from './contact/contact.component';
import { VasServicesComponent } from './vas-services/vas-services.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DevelopersComponent } from './developers/developers.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { CustomerFeedbackComponent } from './web-hosting/customer-feedback/customer-feedback.component';
import { RepairComponent } from './repair/repair.component';
import { RepairPartnerComponent } from './repair/repair-partner/repair-partner.component';
import { TeleSmartComponent } from './TeleSmart/TeleSmart.component';
import { MobileContentComponent } from './Mobile-Content/Mobile-Content.component';
import { DigitalMarketingComponent } from './Digital-Marketing/Digital-Marketing.component';
import { GamesPortalComponent } from './games-portal/games-portal.component';
import { SMSPortalComponent } from './sms-portal/sms-portal.component';
import { MMTNetworkComponent } from './mmt-network/mmt-network.component';
import { CoverageComponent } from './coverage/coverage.component';
import { DeckComponent } from './deck/deck.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HashLocationStrategy } from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    TeamComponent,
    FunfactComponent,
    WorksComponent,
    FeedbackComponent,
    PartnerComponent,
    AboutComponent,
    HomeTeamComponent,
    BlogComponent,
    BlogDetailsComponent,
    ContactComponent,
    ComingSoonComponent,
    NotFoundComponent,
    DevelopersComponent,
    WebHostingComponent,
    CustomerFeedbackComponent,
    RepairComponent,
    RepairPartnerComponent,
    VasServicesComponent,
    TeleSmartComponent,
    MobileContentComponent,
    DigitalMarketingComponent,
    GamesPortalComponent,
    SMSPortalComponent,
    DeckComponent,
    MMTNetworkComponent,
    CoverageComponent
    ],
  imports: [
    SweetAlert2Module,
    BrowserModule,
    AppRoutingModule,
    IconsModule,
    BrowserAnimationsModule,
    CarouselModule,
    FormsModule,
    Ng2OdometerModule.forRoot(),
    SlickCarouselModule, NgwWowModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {}
